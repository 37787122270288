//@flow
import React, { memo } from 'react';

import { Avatar, Grid } from '@mui/material';
import ExtLink from '../ExtLink';
import Text from '../Text';
import format from 'date-fns/format';
import { palette } from '@dt/theme';
import type { HorizonNotificationEvent, SevenhellNotificationEvent } from '@dt/graphql-support/types';
import { makeStyles } from '@mui/styles';

type Props = {|
  notification: HorizonNotificationEvent | SevenhellNotificationEvent,
|};

export const useStyles = makeStyles({
  mainContainer: {
    paddingTop: 16,
    width: 456,
  },
  iconAvatar: {
    width: 32,
    height: 32,
  },
  detailAvatar: {
    width: 16,
    height: 16,
  },
  title: {
    margin: 0,
    paddingTop: 6,
    paddingBottom: 6,
  },
  errorContainer: {
    background: palette.red50,
    width: 456,
    borderRadius: 4,
  },
  zeroStateContainer: {
    background: palette.gray45,
    width: 456,
    borderRadius: 4,
  },
});

const textStyles = {
  text: {
    margin: 0,
    overflow: 'hidden',
  },
  textRegularWidth: {
    margin: 0,
    overflow: 'hidden',
    maxWidth: '415px',
    maxHeight: '65px',
  },
  textSmallWidth: {
    margin: '0px',
    overflow: 'hidden',
    maxWidth: '395px',
    maxHeight: '65px',
  },
};

export default memo<Props>(function NotificationItem({ notification }: Props) {
  const styles = useStyles();
  const { date_created, detail, detail_icon_url, icon_url, portal_url, subtitle, title } = notification;

  return (
    <ExtLink to={portal_url} target="_blank" aria-label={detail}>
      <Grid container direction="row" className={styles.mainContainer} spacing={2}>
        <Grid item xs={1}>
          <Avatar className={styles.iconAvatar} src={icon_url} />
        </Grid>
        <Grid item container direction="column" xs={11}>
          {title ? (
            <Grid item className={styles.title}>
              <Text style={textStyles.textRegularWidth} color={palette.gray20} variant="titleXS">
                {title}
              </Text>
            </Grid>
          ) : null}
          {subtitle ? (
            <Grid item>
              <Text style={textStyles.textRegularWidth} color={palette.gray30} variant="body">
                {subtitle}
              </Text>
            </Grid>
          ) : null}
          <Grid
            item
            container
            direction="row"
            spacing={2}
            alignContent="center"
            alignItems="center"
            className={styles.title}
          >
            <Grid item style={{ alignSelf: 'flex-start', paddingTop: '10px' }} xs={1}>
              <Avatar className={styles.detailAvatar} src={detail_icon_url} />
            </Grid>
            <Grid item xs={11}>
              <Text style={textStyles.textSmallWidth} color={palette.gray20} variant="body">
                {detail}
              </Text>
            </Grid>
          </Grid>
          <Grid item>
            <Text style={textStyles.text} color={palette.gray35} variant="caption">
              {format(date_created, 'M/DD/YYYY, h:mm:ss A')}
            </Text>
          </Grid>
        </Grid>
      </Grid>
    </ExtLink>
  );
});
