// @flow
import React, { memo, type Node } from 'react';
import { makeStyles } from '@mui/styles';
import { type Properties } from 'csstype';

import { palette } from '@dt/theme';

let variantToBgColor = {
  gray: palette.gray45,
  red: palette.red50,
  green: palette.green50,
  blue: palette.blue50,
  yellow: palette.yellow50,
  transparent: 'transparent',
};

let variantToColor = {
  gray: palette.gray20,
  red: palette.red20,
  green: palette.green20,
  blue: palette.blue20,
  yellow: palette.gray20,
  transparent: palette.gray20,
};

let useStyle = makeStyles({
  box: props => ({
    padding: props.noPadding ? 0 : '2px 4px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    backgroundColor: variantToBgColor[props.variant || 'gray'],
    color: variantToColor[props.variant || 'gray'],
    borderRadius: props.rounded ? '16px' : '4px',
    fontSize: '14px',
  }),
});

type Props = {
  children: Node,
  variant?: 'gray' | 'red' | 'green' | 'blue' | 'yellow' | 'transparent',
  rounded?: boolean,
  noPadding?: boolean,
  classes?: string,
  style?: Properties<>,
};

function Label({ children, variant, classes, style = {}, rounded = false, noPadding = false }: Props) {
  let css = useStyle({ variant, rounded, noPadding });
  return (
    <span className={`${css.box} ${classes || ''}`} style={style}>
      {children}
    </span>
  );
}

export default memo<Props>(Label);
