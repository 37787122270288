//@flow
import React, { PureComponent } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import type { Notification } from './reducer';

type Props = {|
  notifications: $ReadOnlyArray<Notification>,
  onDismissClick: (notification: Notification) => mixed,
|};

class Notifier extends PureComponent<Props> {
  render() {
    const { notifications } = this.props;
    const notification = notifications.length ? notifications[notifications.length - 1] : null;

    if (!notification) {
      return null;
    }

    return notification.severity ? (
      <Snackbar
        open={true}
        autoHideDuration={5000}
        onClose={(e, snackbarCloseReason) => {
          if (snackbarCloseReason === 'timeout') {
            this.handleClickDismiss(notification);
          }
        }}
      >
        <Alert
          variant="filled"
          severity={notification.severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => this.handleClickDismiss(notification)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {notification.text}
        </Alert>
      </Snackbar>
    ) : (
      <Snackbar
        open={true}
        message={notification.text}
        action={
          <Button color="secondary" onClick={() => this.handleClickDismiss(notification)}>
            Dismiss
          </Button>
        }
        autoHideDuration={5000}
        onClose={() => {}}
      />
    );
  }

  handleClickDismiss = (notification: ?Notification) => {
    if (!notification) {
      throw new Error('Tried to dismiss nonexistant notification');
    }

    const { onDismissClick } = this.props;
    if (onDismissClick) {
      onDismissClick(notification);
    }
  };
}

export default Notifier;
